@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

#root,
.app,
html,
body {
  height: 100%;
  width: 100%;  
  font-family: 'Roboto',sans-serif;
  overflow: hidden;
}

/*
:-webkit-scrollbar {
  width: 0,  
}*/

/* Hide scrollbar for Chrome, Safari, and Opera */
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Edge and Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Optional: If you want to hide the scrollbar for all browsers */
.scrollbar-hidden {
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #7a7f9d;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #21295c;
}

/* Handle on Hover */
::-webkit-scrollbar-track:hover {
  background: #21295c;
}

.react-datepicker-popper{
  display: none !important;
}

.MuiBox-root .css-1bvc4cc{
  display: flex;
  max-width: fit-content;
  text-align-last: center;
}

.timeComponent input[type="text"] {
  max-width: 70%; /* Adjust the width as needed */
}

*,*:focus,*:hover{
  outline:none;
}

.timeComponent:hover{
color: black;
cursor: pointer;
user-select: none;
}

.rdrDateDisplayWrapper{
  background-color: #FFFFFF !important;
}

.wezignInput{
 border : 2px solid rgb(6, 176, 133) !important;
}

img {
  -webkit-user-drag: none;
}

/* For screens smaller than 600px */
@media screen and (max-width: 599px) {
  html,
  body {
    overflow: auto !important;
  }
  .rightSideLogin{
    display: none;
  }
  .image-wrapper-notfound {
    width: 300px; 
    height: 200px; 
    background-image: url('./assets/logoSmallNotFound.png');
    background-size: contain; 
    background-position: center; 
    background-repeat: no-repeat;
}
.image-wrapper-notfound .logoNotFound{
  display: none;
}
  .Intro{
    background-image: url('./assets/backgroundIntro.svg');
  }
  .Wlcm_wezign{
    color : #ffffff !important;
  }
  .LogoIntro_Wezign{
    width : 8em !important;
  }
  .desktop_bubbles{
    display: none;
  }
  
}
  


/* For screens 600px and above */
@media screen and (min-width: 600px) {
  html,
  body {
    overflow: hidden;
  }
  .LogoIntro_Wezign{
    width : 5.5em !important;
  }
  .LogoNotFound{
    width : 10rem !important;
  }
  .image-wrapper-notfound {
    display: contents;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Geist";
    src: url("./Geist-Black.otf") format("opentype");
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Geist";
    src: url("./Geist-Bold.otf") format("opentype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Geist";
    src: url("./Geist-SemiBold.otf") format("opentype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Geist";
    src: url("./Geist-Regular.otf") format("opentype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Geist";
    src: url("./Geist-Medium.otf") format("opentype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


body {
    font-family: "Geist";
}

:root {
    --fc-button-bg-color: #566ff1;
    --fc-button-border-color: #566ff1;
    --fc-today-bg-color: #fff5e9;
}

.fc .fc-button {
    border-radius: 0.5rem;
}